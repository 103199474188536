<template>
  <div style="height: 100%">
    <!-- toolbar -->
    <v-toolbar dense flat>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="lastPage()" v-bind="attrs" v-on="on">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Up to dataset list page</span>
      </v-tooltip>

      <v-toolbar-title v-if="datasetGroup"
        >{{ datasetGroup.name }} ({{ $route.params.id }})</v-toolbar-title
      >

      <!-- <v-tooltip bottom :disabled="msg === null">
        <template v-slot:activator="{ on, attrs }">
          <span class="ml-auto" v-on="on">
            <v-btn
              color="primary"
              v-bind="attrs"
              @click.stop="isModelCreateActive = !isModelCreateActive"
              :disabled="isTrainBtnLocked"
            >
              TRAIN NEW MODEL
            </v-btn>
          </span>
        </template>
        <span v-if="msg">{{ msg }}</span>
      </v-tooltip> -->
    </v-toolbar>
    <v-divider></v-divider>

    <!-- tab toolbar-->
    <!-- <v-tabs v-model="tab">
      <v-tab v-for="item in items" :key="item.name" @click="onClick(item)">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-divider></v-divider> -->

    <!-- tab content -->
    <router-view></router-view>

    <!-- model create drawer-->
    <!-- <model-create
      v-if="isModelCreateActive"
      v-model="isModelCreateActive"
    ></model-create> -->
  </div>
</template>

<script>
import data from "@/models/data";

export default {
  data() {
    return {
      tab: null,
      items: [
        { name: "Info", path: "info" },
        { name: "Causal graph", path: "causal-graph" },
        // { name: "Target & Tactics", path: "tactic" },
        // { name: "Price", path: "price" },
        // { name: "Tactic Groups", path: "group" },
      ],
      isModelCreateActive: false,
      isTrainBtnLocked: true,
      msg: null,
      datasetGroup: null,
    };
  },
  components: {
    // ModelCreate: () => import("@/components/model/ModelCreate.vue"),
  },
  methods: {
    onClick(item) {
      this.$router.push({
        path: `/datasetGroups/${this.$route.params.id}/${item.path}`,
      });
    },
    setTabByUrl() {
      const paths = this.$route.path.split("/");
      const path = paths[paths.length - 1];
      const index = this.items.findIndex((item) => {
        return item.path === path;
      });
      this.tab = index;
    },
    async updateTrainBtnStatus() {
      try {
        const id = this.$route.params.id;
        // const response = await data.getTrainStatus(UID);
        const response = await data.getDatasetGroup(id);
        console.log("response", response);
        // const msg = response.data[0];
        if (response.active === true) {
          this.msg = null;
          this.isTrainBtnLocked = false;
        }
        this.datasetGroup = response;
        // else if (msg === "Must fill in the Target and Tactic form first.") {
        //   this.msg = msg;
        // } else if (msg === "Must fill in the Price form first.") {
        //   this.msg = msg;
        // }
      } catch (error) {
        console.error(error);
      }
    },
    lastPage() {
      const routeName = this.$route.name;
      if (
        routeName === "dataset-groups-tab-causal-graph-editor" ||
        routeName === "dataset-groups-tab-model-create" ||
        routeName === "dataset-groups-data-view" ||
        routeName === "dataset-groups-tab-open-source-data"
      ) {
        const id = this.$route.params.id;
        this.$router.push({
          path: `/datasetGroups/${id}`,
        });
      } else if (routeName === "dataset-groups-tab-open-source-view") {
        const id = this.datasetGroup.id;
        this.$router.push({
          path: `/datasetGroups/${id}/open-source-data`,
        });
      } else if (routeName === "dataset-groups-overview") {
        this.$router.push("/datasetGroups");
      } else {
        this.$router.push("/datasetGroups");
      }
    },
  },
  created() {
    this.setTabByUrl();
    this.updateTrainBtnStatus();
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      datasetName: this.$route.query.name,
    };
  },
  watch: {
    // when url changed, set the tab by the url
    $route() {
      this.setTabByUrl();
    },
  },
};
</script>
